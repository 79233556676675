<template>
  <UiActionBar
    v-model="modelValue"
    :title="`${selectedIndexes.filter((s) => s).length} leads selected`"
    secondary-button-text="Cancel"
    @cancel="$emit('cancel')"
  >
    <div class="flex flex-row items-center gap-4">
      <UiTooltip name="assign" position-top :width="200">
        <template #activator>
          <button
            id="assign_agent"
            class="rounded-xl p-2 text-primary-20 transition hover:bg-white/10 hover:text-white"
            @click="showAssignAgentPopup"
          >
            <UiIcon name="user"></UiIcon>
          </button>
        </template>
        <template #content> Assign user to the selected leads </template>
      </UiTooltip>
    </div>
  </UiActionBar>
</template>

<script setup lang="ts">
import { POPUPS } from '~/components/dynamic/maps'
import { useUiStore } from '~/store/ui'
import type { Lead } from '~/types'

const modelValue = defineModel<boolean>()

const emits = defineEmits(['input', 'cancel'])

const uiStore = useUiStore()

type Props = {
  leads: Lead[]
  selectedIndexes: boolean[]
}
const props = defineProps<Props>()

const showAssignAgentPopup = () => {
  uiStore.showPopup(
    POPUPS.PIPELINE_LEAD_ASSIGN_AGENT,
    {
      selectedLeads: props.leads.filter((_: Lead, i: number) => props.selectedIndexes[i]),
      fromQueue: true,
      warnTitle: 'Oops! Some leads already assigned ',
      showDefaultSnackBar: true,
    },
    {
      input: () => {
        emits('input')
        modelValue.value = false
      },
    }
  )
}
</script>

<style scoped></style>
